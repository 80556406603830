import { encrypt } from "./auth";
export const getLoginToken = (authData) => {
    const { code, username: loginId, password } = authData;
    const date = new Date();
    const formattedDate = date
        .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        })
        .replace(/ /g, "-");
    const formattedTime = date.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    const authString = encrypt(
        [formattedDateTime, 1, code, loginId, password].join("~")
    );
    return "?authentication=" + encodeURIComponent(authString);
};
export const getRedirectUrl = (values) => {
    const { loginFor = "B" } = values;
    const { hostname } = window.location;
    let url = "";
    if (loginFor === "B") {
        url = "https://bus.mavenconnect.tech/";
        if (hostname.includes("mavenconnect.biz")) {
            url = "https://bus.mavenconnect.biz/";
        }
    } else if (loginFor === "C") {
        url = "https://cargo.mavenconnect.tech/";
        if (hostname.includes("mavenconnect.biz")) {
            url = "https://cargo.mavenconnect.biz/";
        }
    }
    if (!url.length) url = "https://cargo.mavenconnect.tech/";
    url = url + getLoginToken(values);
    return url;
};
export const getMaintenanceurl = () => {
    let url = "https://vms.mavenconnect.tech/login";
    if (window.location.hostname.includes("mavenconnect.biz")) {
        url = "https://vms.mavenconnect.biz/login";
    }
    return url;
};
