import { Helmet } from "react-helmet";

export const PrivacyPolicy = () => (
    <div className="container privacy-page">
        <Helmet>
            <title>Maven Tech Labs :: Privacy Policy</title>
        </Helmet>
        <div className="header-wrap">
            <div className="header-wrap-left">
                <img src="/maventech-logo.png" />
            </div>
            <div className="company-name">Maventech Labs Private Limited</div>
            <div className="company-address">
                Gopala Krishna Complex, 1st Floor, #45/3 Residency Road,
                Bangaluru - 560025, Karnataka, India.
            </div>
        </div>
        <div className="body-wrap">
            <h1 className="heading1">Privacy Policy</h1>
            <p className="last-updated">
                Last updated: 01<sup>st</sup> September 2021
            </p>
            <p>
                This Privacy Notice describes how we collect and use your
                personal information in relation to MAVENTECH LABS PRIVATE
                LIMITED (“hereinafter referred as “MAVENTECH”) websites,
                applications, products, services, events, and experiences that
                reference this Privacy Notice (together, “MAVENTECH Offerings”).
            </p>

            <p>
                This Privacy Notice does not apply to the “content” processed,
                stored, or hosted by our customers using MAVENTECH Offerings in
                connection with an MAVENTECH account. Read this agreement
                governing your access to your MAVENTECH account for more
                information about how we handle content and how our customers
                can control their content through MAVENTECH Offerings. This
                Privacy Notice also does not apply to any products, services,
                websites, or content that are offered by third parties or have
                their own privacy notice.
            </p>

            <h2 className="heading2">Personal Information We Collect</h2>

            <p>
                We collect your personal information in the course of providing
                MAVENTECH Offerings to you.
            </p>

            <p>Here are the types of information we gather:</p>

            <ul>
                <li>
                    Information You Give Us: We collect any information you
                    provide in relation to MAVENTECH Offerings.
                </li>
                <li>
                    Automatic Information: We automatically collect certain
                    types of information when you interact with MAVENTECH
                    Offerings.
                </li>
                <li>
                    Information from Other Sources: We might collect information
                    about you from other sources, including service providers,
                    partners, and publicly available sources.
                </li>
            </ul>

            <h2 className="heading2">How We Use Personal Information</h2>

            <p>
                We use your personal information to operate, provide, and
                improve MAVENTECH Offerings. Our purposes for using personal
                information include:
            </p>

            <ul>
                <li>
                    <b>Provide MAVENTECH Offerings:</b> We use your personal
                    information to provide and deliver MAVENTECH Offerings and
                    process transactions related to MAVENTECH Offerings,
                    including registrations, subscriptions, purchases, and
                    payments.
                </li>
                <li>
                    <b>Measure, Support, and Improve MAVENTECH Offerings:</b> We
                    use your personal information to measure use of, analyse
                    performance of, fix errors in, provide support for, improve,
                    and develop MAVENTECH Offerings.
                </li>
                <li>
                    <b>Recommendations and Personalization:</b> We use your
                    personal information to recommend MAVENTECH Offerings that
                    might be of interest to you, identify your preferences, and
                    personalize your experience with MAVENTECH Offerings.
                </li>
                <li>
                    <b>Comply with Legal Obligations:</b> In certain cases, we
                    have a legal obligation to collect, use, or retain your
                    personal information.
                </li>
                <li>
                    <b>Communicate with You:</b> We use your personal
                    information to communicate with you in relation to MAVENTECH
                    Offerings via different channels (e.g., by phone, email,
                    chat) and to respond to your requests.
                </li>
                <li>
                    <b>Marketing:</b> We use your personal information to market
                    and promote MAVENTECH Offerings. We might display
                    interest-based ads for MAVENTECH Offerings.
                </li>
                <li>
                    <b>Fraud and Abuse Prevention and Credit Risks:</b> We use
                    your personal information to prevent and detect fraud and
                    abuse in order to protect the security of our customers,
                    MAVENTECH, and others. We may also use scoring methods to
                    assess and manage credit risks.
                </li>
                <li>
                    <b>Purposes for Which We Seek Your Consent:</b> We may also
                    ask for your consent to use your personal information for a
                    specific purpose that we communicate to you.
                </li>
            </ul>

            <h2 className="heading2">Cookies</h2>

            <p>
                To enable our systems to recognize your browser or device and to
                provide MAVENTECH Offerings, we use cookies.
            </p>

            <h2 className="heading2">How We Share Personal Information</h2>

            <p>
                Information about our customers is an important part of our
                business and we are not in the business of selling our
                customers’ personal information to others. We share personal
                information only as described below and with MAVENTECH LABS
                PRIVATE LIMITED, and the affiliates that MAVENTECH LABS PRIVATE
                LIMITED, controls that are either subject to this Privacy Notice
                or follow practices at least as protective as those described in
                this Privacy Notice.
            </p>

            <ul>
                <li>
                    <b>Transactions Involving Third Parties:</b> We make
                    available to you services, software, and content provided by
                    third parties for use on or through MAVENTECH Offerings. You
                    can tell when a third party is involved in your
                    transactions, and we share information related to those
                    transactions with that third party. For example, you can
                    order services, software, and content from sellers using the
                    MAVENTECH and we provide those sellers information to
                    facilitate your subscription, purchases, or support.
                </li>
                <li>
                    <b>Third-Party Service Providers:</b> We employ other
                    companies and individuals to perform functions on our
                    behalf. Examples include: delivering MAVENTECH hardware,
                    sending communications, processing payments, assessing
                    credit and compliance risks, analysing data, providing
                    marketing and sales assistance (including advertising and
                    event management), conducting customer relationship
                    management, and providing training. These third-party
                    service providers have access to personal information needed
                    to perform their functions, but may not use it for other
                    purposes. Further, they must process that information in
                    accordance with this Privacy Notice and as permitted by
                    applicable data protection law.
                </li>
                <li>
                    <b>Business Transfers:</b> As we continue to develop our
                    business, we might sell or buy businesses or services. In
                    such transactions, personal information generally is one of
                    the transferred business assets but remains subject to the
                    promises made in any pre-existing Privacy Notice (unless, of
                    course, the individual consents otherwise). Also, in the
                    unlikely event that MAVENTECH or substantially all of its
                    assets are acquired, your information will of course be one
                    of the transferred assets.
                </li>
                <li>
                    <b>Protection of Us and Others:</b> We release account and
                    other personal information when we believe release is
                    appropriate to comply with the law, enforce or apply our
                    terms and other agreements, or protect the rights, property,
                    or security of MAVENTECH, our customers, or others. This
                    includes exchanging information with other companies and
                    organizations for fraud prevention and detection and credit
                    risk reduction.
                </li>
                <li>
                    <b>At Your Option:</b> Other than as set out above, you will
                    receive notice when personal information about you might be
                    shared with third parties, and you will have an opportunity
                    to choose not to share the information.
                </li>
            </ul>

            <h2 className="heading2">Location of Personal Information</h2>
            <p>
                MAVENTECH is located in the INDIA, and our affiliated companies
                are located throughout the world. Depending on the scope of your
                interactions with MAVENTECH Offerings, your personal information
                may be stored in or accessed from multiple countries, including
                the India. Whenever we transfer personal information to other
                jurisdictions, we will ensure that the information is
                transferred in accordance with this Privacy Notice and as
                permitted by applicable data protection policy.
            </p>

            <h2 className="heading2">How We Secure Information</h2>
            <p>
                At MAVENTECH, security is our highest priority. We design our
                systems with your security and privacy in mind.
            </p>

            <ul>
                <li>
                    We maintain a wide variety of compliance programs that
                    validate our security controls.{" "}
                </li>
                <li>
                    We protect the security of your information during
                    transmission to or from MAVENTECH websites, applications,
                    products, or services by using encryption protocols and
                    software.
                </li>
                <li>
                    We maintain physical, electronic, and procedural safeguards
                    in connection with the collection, storage, and disclosure
                    of personal information. Our security procedures mean that
                    we may request proof of identity before we disclose personal
                    information to you.
                </li>
            </ul>

            <h2 className="heading2">Internet Advertising and Third Parties</h2>
            <p>
                MAVENTECH Offerings may include third-party advertising and
                links to other websites and applications. Third party
                advertising partners may collect information about you when you
                interact with their content, advertising, or services.
            </p>
            <h2 className="heading2">Access and Choice</h2>
            <p>
                You can view, update, and delete certain information about your
                account and your interactions with MAVENTECH Offerings. If you
                cannot access or update your information yourself, you can
                always contact us for assistance.
            </p>
            <p>
                You have choices about the collection and use of your personal
                information. Many MAVENTECH Offerings include settings that
                provide you with options as to how your information is being
                used. You can choose not to provide certain information, but
                then you might not be able to take advantage of certain
                MAVENTECH Offerings.
            </p>

            <ul>
                <li>
                    <b>Account Information:</b> If you want to add, update, or
                    delete information related to your account, please go to the
                    appropriate setting and configuration page in the respective
                    MAVENTECH Offerings. When you update or delete any
                    information, we usually keep a copy of the prior version for
                    our records.
                </li>
                <li>
                    <b>Communications:</b> If you do not want to receive
                    promotional messages from us, please unsubscribe or contact
                    us. If you do not want to receive in-app notifications from
                    us, please adjust your notification settings in the app or
                    your device.
                </li>
                <li>
                    <b>Browser and Devices:</b> The Help feature on most
                    browsers and devices will tell you how to prevent your
                    browser or device from accepting new cookies, how to have
                    the browser notify you when you receive a new cookie, or how
                    to disable cookies altogether.
                </li>
                <li>
                    <b>Clients and MAVENTECH Partners:</b> Clients and MAVENTECH
                    partner members can add, update, or delete information in
                    the MAVENTECH Offerings.
                </li>
            </ul>

            <h2 className="heading2">Children’s Personal Information</h2>
            <p>
                We don’t provide MAVENTECH Offerings for purchase by children.
                If you’re under 18, you may use MAVENTECH Offerings only with
                the involvement of a parent or guardian.
            </p>

            <h2 className="heading2">Retention of Personal Information</h2>

            <p>
                We keep your personal information to enable your continued use
                of MAVENTECH Offerings, for as long as it is required in order
                to fulfil the relevant purposes described in this Privacy
                Notice, as may be required by law (including for tax and
                accounting purposes), or as otherwise communicated to you. How
                long we retain specific personal information varies depending on
                the purpose for its use, and we will delete your personal
                information in accordance with applicable law.
            </p>

            <h2 className="heading2">Contacts, Notices, and Revisions</h2>
            <p>
                If you have any concerns about privacy at MAVENTECH, please
                contact us with a thorough description, and we will try to
                resolve the issue for you.
            </p>
            <p>
                For any prospective or current customers of Maventech Labs
                Private Limited, our mailing address is: Gopala Krishna Complex,
                1st Floor, #45/3 Residency Road, Bengaluru, Bangalore560025,
                Karnataka, India
            </p>
            <p>
                If you interact with MAVENTECH Offerings on behalf of or through
                your organization, then your personal information may also be
                subject to your organization’s privacy practices, and you should
                direct privacy inquiries to your organization.
            </p>
            <p>
                Our business changes constantly, and our Privacy Notice may also
                change. You should check our website frequently to see recent
                changes. You can see the date on which the latest version of
                this Privacy Notice was posted. Unless stated otherwise, our
                current Privacy Notice applies to all personal information we
                have about you and your account. We stand behind the promises we
                make, however, and will never materially change our policies and
                practices to make them less protective of personal information
                collected in the past without informing affected customers and
                giving them a choice.
            </p>

            <h2 className="heading2">Examples of Information Collected</h2>
            <p>
                <b>Information You Give Us</b>
                <br />
                You provide information to us when you:
            </p>

            <ul>
                <li>
                    search for, subscribe to, or purchase MAVENTECH Offerings;
                </li>
                <li>
                    create or administer your MAVENTECH account (and you might
                    have more than one account if you have used more than one
                    email address or mobile number when using MAVENTECH
                    Offerings);
                </li>
                <li>
                    configure your settings for, provide data access permissions
                    for, or otherwise interact with MAVENTECH Offerings;
                </li>
                <li>register for or attend an MAVENTECH event;</li>
                <li>
                    purchase or use content, products, or services from
                    third-party providers through the MAVENTECH Offerings (or
                    other similar venues operated or provided by us);
                </li>
                <li>
                    offer your content, products, or services on or through
                    MAVENTECH Offerings or the MAVENTECH (or other similar
                    venues operated or provided by us);
                </li>
                <li>communicate with us by phone, email, or otherwise;</li>
                <li>
                    complete a questionnaire, a support ticket, or other
                    information request forms;
                </li>
                <li>
                    post on MAVENTECH websites or participate in community
                    features; and
                </li>
                <li>employ notification services.</li>
            </ul>
            <p>
                Depending on your use of MAVENTECH Offerings, you might supply
                us with such information as:
            </p>

            <ul>
                <li>
                    your name, email address, physical address, phone number,
                    and other similar contact information;
                </li>
                <li>
                    payment information, including credit card and bank account
                    information;
                </li>
                <li>information about your location;</li>
                <li>
                    information about your organization and your contacts, such
                    as colleagues or people within your organization;
                </li>
                <li>
                    usernames, aliases, roles, and other authentication and
                    security credential information;
                </li>
                <li>
                    content of feedback, testimonials, inquiries, support
                    tickets, and any phone conversations, chat sessions and
                    emails with or to us;
                </li>
                <li>
                    your image (still, video, and in some cases 3-D), voice, and
                    other identifiers that are personal to you when you attend
                    an MAVENTECH event or use certain MAVENTECH Offerings;
                </li>
                <li>
                    information regarding identity, including government-issued
                    identification information;
                </li>
                <li>corporate and financial information; and</li>
                <li>GSTIN, PAN, VAT numbers and other tax identifiers.</li>
            </ul>

            <p>
                <b>Automatic Information</b>
                <br />
                We collect information automatically when you:
            </p>

            <ul>
                <li>
                    visit, interact with, or use MAVENTECH Offerings (including
                    when you use your computer or other device to interact with
                    MAVENTECH Offerings);
                </li>
                <li>download content from us;</li>
                <li>open emails or click on links in emails from us; and</li>
                <li>
                    interact or communicate with us (such as when you attend an
                    MAVENTECH event or when you request customer support).
                </li>
            </ul>

            <p>Examples of the information we automatically collect include:</p>

            <ul>
                <li>
                    network and connection information, such as the Internet
                    protocol (IP) address used to connect your computer or other
                    device to the Internet and information about your Internet
                    service provider;
                </li>
                <li>
                    computer and device information, such as device,
                    application, or browser type and version, browser plug-in
                    type and version, operating system, or time zone setting;
                </li>
                <li>the location of your device or computer;</li>
                <li>authentication and security credential information;</li>
                <li>
                    content interaction information, such as content downloads,
                    streams, and playback details, including duration and number
                    of simultaneous streams and downloads;
                </li>
                <li>
                    MAVENTECH Offerings metrics, such as offering usage,
                    occurrences of technical errors, diagnostic reports, your
                    settings preferences, backup information, API calls, and
                    other logs;
                </li>
                <li>
                    the full Uniform Resource Locators (URL) clickstream to,
                    through, and from our website (including date and time) and
                    MAVENTECH Offerings, content you viewed or searched for,
                    page response times, download errors, and page interaction
                    information (such as scrolling, clicks, and mouse-overs);
                </li>
                <li>
                    email addresses and phone numbers used to contact us; and
                </li>
                <li>identifiers and information contained in cookies. </li>
            </ul>

            <p>
                <b>Information from Other Sources</b>
                <br />
                Examples of information we receive from other sources include:
            </p>

            <ul>
                <li>
                    marketing, sales generation, and recruitment information,
                    including your name, email address, physical address, phone
                    number, and other similar contact information;
                </li>
                <li>
                    subscription, purchase, support, or other information about
                    your interactions with products and services offered by us,
                    our affiliates (such as MAVENTECH training courses), or
                    third parties (such as products offered through the
                    MAVENTECH partners) in relation to MAVENTECH Offerings;
                </li>
                <li>
                    search results and links, including paid listings (such as
                    Sponsored Links); and
                </li>
                <li>
                    credit and tax history information from credit bureaus, tax
                    authorities.
                </li>
            </ul>

            <p>
                <b>Information You Can Access</b>
                <br />
                Examples of information you can access through MAVENTECH
                Offerings include:
            </p>

            <ul>
                <li>
                    your name, email address, physical address, phone number,
                    and other similar contact information;
                </li>
                <li>
                    usernames, aliases, roles, and other authentication and
                    security credential information;
                </li>
                <li>
                    your subscription, purchase, usage, billing, and payment
                    history;
                </li>
                <li>
                    payment settings, such as payment instrument information and
                    billing preferences;
                </li>
                <li>tax information; and</li>
                <li>
                    if you participate in the MAVENTECH partner Network (or
                    other similar venues operated or provided by us), your
                    account, your status, subscriptions, and other information.
                </li>
            </ul>

            <p>
                <b>Grievance Contact Points</b>
                <br />
                For any prospective or current customers of Maventech Labs
                Private Limited, for any grievances our contact address and
                Grievance officer details are as follows:
            </p>

            <ul>
                <li>
                    <b>Grievance Officer:</b> Sunita Iyer (Customer Service
                    Head)
                </li>
                <li>
                    <b>Mailing address:</b> Gopala Krishna Complex, 1st Floor,
                    #45/3 Residency Road, Bengaluru, Bangalore-560025,
                    Karnataka, India
                </li>
                <li>
                    <b>Customer Care Number:</b> 08047096674
                </li>
                <li>
                    <b>Customer Care Email Address:</b> feedback@travelyaari.com
                </li>
            </ul>
        </div>
        <div className="footer-wrap">
            <div className="footer-line">&nbsp;</div>
            <div className="footer-info">
                <div className="footer-info-left">
                    CIN Number: U72900KA2020PTC136105
                </div>
                <div className="footer-info-center"></div>
                <div className="footer-info-right">GSTIN: 29AANCM6095M1ZH</div>
            </div>
        </div>
    </div>
);
