import { Helmet } from "react-helmet";

export const Terms = () => (
    <div className="container terms-page">
        <Helmet>
            <title>
                Maven Tech Labs :: Terms of Use for Online Software License and
                Maintenance Agreement
            </title>
        </Helmet>
        <div className="header-wrap">
            <div className="header-wrap-left">
                <img src="/maventech-logo.png" />
            </div>
            <div className="company-name">
                    Maventech Labs Private Limited
                </div>
                <div className="company-address">
                    Gopala Krishna Complex, 1st Floor, #45/3 Residency Road,
                    Bangaluru - 560025, Karnataka, India.
                </div>
            <div className="header-warp-right">
                
            </div>
        </div>

        <div className="body-wrap">
            <div className="start-page">
                <p>
                    TERMS OF USE AGREEMENT FOR SOFTWARE LICENSE AND MAINTENANCE{" "}
                </p>
                <p>FOR</p>
                <p>
                    MAVENTECH BUSCRS™, MAVENCONNECT™, MAVEN™, BOOKBUSTICKETS AND
                    OTHER SOFTWARES
                </p>
            </div>
            <ul className="tnc-wrap">
                <li className="srno">1.</li>
                <li className="content">
                    DEFINITIONS AND RULES OF INTERPRETATION
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">1.1</li>
                <li className="content">Definitions</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    In this Agreement (including the recitals above and the
                    Schedules hereto), except where the context otherwise
                    requires, the following words and expressions shall have the
                    following meanings:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i)</li>
                        <li className="content">
                            “Agent” shall mean any person who is authorized to
                            act on behalf of either of the Parties, to legally
                            bind the said Party in particular business
                            transactions with third parties pursuant to an
                            agency relationship.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii) </li>
                        <li className="content">
                            “Agreement” means this Agreement, the exhibits
                            annexed hereto and includes each of them as may be
                            amended from time to time by MAVENTECH at its sole
                            discretion.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii) </li>
                        <li className="content">
                            “Application Programming Interface / API” is an
                            interface implemented by a software program
                            developed by MAVENTECH to enable interaction with
                            software provided to USER for the purpose of this
                            Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iv) </li>
                        <li className="content">
                            “Go-Live” means the date mentioned in Exhibit A.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(v) </li>
                        <li className="content">
                            “Intellectual Property” means all patents and patent
                            applications; trademarks, service marks, and
                            trademark or service mark registrations and
                            applications, trade names, Internet domain names,
                            logos, designs, slogans, and general intangible of
                            like nature, together with all goodwill related to
                            the foregoing; copyrights registration, renewals and
                            applications for copyrights, software, technology,
                            trade secrets and other confidential information,
                            knowhow, proprietary processes, formulae,
                            algorithms, model and methodologies, rights of
                            privacy and publicity, and Usage License agreement
                            relating to any of the foregoing.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(vi) </li>
                        <li className="content">
                            “BusCRS” and/ or “MAVENCONNECT” refers to MAVENTECH
                            Bus Computerised Reservation Software (CRS) or
                            Online Passenger Reservation Software (OPRS) which
                            is an online bus ticketing platform / software used
                            by bus transporters to book bus tickets through its
                            counters or agents’ outlets. MAVENCONNECT comprises
                            of two components – the API and windows desktop
                            application. BusCRS comprises of the online web
                            portal www.buscrs.com and www.bookbustickets.com.
                            MAVENCONNECT and BusCRS is seamlessly connected with
                            each other.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(vii) </li>
                        <li className="content">
                            “MAVEN” refers to MAVENTECH Bus FARE COLLECTION
                            SOFTWARE (AFCS) which is an Android based Point of
                            Sales (POS) app enabling bus and cargo booking on
                            POS machines/ devices along with ability to collect
                            digital payments. MAVEN is seamlessly connected with
                            MAVENCONNECT and BusCRS.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(viii) </li>
                        <li className="content">
                            “MAVENTECH” means MAVENTECH LABS PRIVATE LIMITED, a
                            company incorporated under the Companies Act, 2013,
                            having its registered office at 1st Floor, Gopala
                            Krishna Complex, #45/3 Residency Road, Bengaluru –
                            560025, Karnataka, India.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ix) </li>
                        <li className="content">
                            “Party” shall individually mean MAVENTECH or USER,
                            as the context may require.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(x) </li>
                        <li className="content">
                            “Parties” shall collectively mean MAVENTECH and
                            USER, as the context may require.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(xi) </li>
                        <li className="content">
                            “Person” shall mean any natural person, individual,
                            firm, company, a government agency or political
                            subdivision thereof; association, syndicate,
                            partnership, joint venture, trustee, trust,
                            corporation, division of a corporation,
                            unincorporated organization or other entity (whether
                            or not having separate legal personality).
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(xii) </li>
                        <li className="content">
                            “Related Parties” shall mean any Person, any other
                            person directly or indirectly controlling,
                            controlled by, or under common control with, such
                            person at any time during the period for which the
                            determination of affiliation is being made and with
                            respect to any natural person, any relative of such
                            person.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(xiii) </li>
                        <li className="content">
                            “USER AWS Account” means any server infrastructure
                            will shall on Amazon AWS or any other cloud hosting
                            company and wherein server hosting charges shall be
                            billed to USER or any other company so desired by
                            USER and communicated to MAVENTECH in writing.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(xiv) </li>
                        <li className="content">
                            “Usage License” is an arrangement of usage of
                            MAVENTECH software products like “BusCRS”,
                            “MAVENCONNECT” and “MAVEN” by USER for its own bus
                            operations wherein the software code of such
                            products shall be hosted on server infrastructure
                            configured under USER AWS ACCOUNT. Intellectual
                            Property Rights of “BusCRS”, “MAVENCONNECT” and
                            “MAVEN” and all future customizations and
                            enhancements of the software products, “BusCRS”,
                            “MAVENCONNECT” and “MAVEN”, shall be of MAVENTECH
                            only.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(xv) </li>
                        <li className="content">
                            “USER” means the Person that has been granted the
                            Usage License by MAVENTECH under this Agreement and
                            the USER is bound by the provisions of this
                            Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">1.2</li>
                <li className="content">Interpretation</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    Unless the context otherwise requires in this Agreement:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i) </li>
                        <li className="content">
                            Neutral pronouns and variations thereof shall be
                            deemed to include the feminine and masculine and all
                            terms used in the singular shall be deemed to
                            include the plural, and vice versa, as the context
                            may require.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii)</li>
                        <li className="content">
                            The word “or” has the inclusive meaning “and/or”.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii)</li>
                        <li className="content">
                            The words “hereto,” this Agreement: and words of
                            similar import refer to this Agreement as a whole,
                            including any attachments to this Agreement, as the
                            same may from time to time be amended or
                            supplemented and not merely any subdivision
                            contained in this Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iv)</li>
                        <li className="content">
                            The word “including” and “such as” when used herein
                            are not intended to be exclusive and in all case
                            mean “including without limitation and such as by
                            way of example but without limitation,”
                            respectively.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(v)</li>
                        <li className="content">
                            Captions or heading are only for reference and are
                            not to be considered in interpreting this Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(vi)</li>
                        <li className="content">
                            words importing persons or parties shall include
                            firms and corporations and any organizations having
                            legal capacity;
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(vii)</li>
                        <li className="content">
                            reference to statutory provisions shall be construed
                            as meaning and including references also to any
                            amendment or re-enactment (whether before or after
                            the date of this Agreement) for the time being in
                            force and to all statutory instruments or orders
                            made pursuant to such statutory provisions.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.</li>
                <li className="content">SCOPE OF ENGAGEMENT</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.1</li>
                <li className="content">
                    Subject to the terms and conditions of this Agreement,
                    MAVENTECH agrees to grant a non-transferable software Usage
                    License to USER to use MAVENTECH proprietary software
                    product BusCRS, MAVENCONNECT and MAVEN, and such allied
                    software so licensed by MAVENTECH (collectively for sake of
                    brevity referred to herein as “MAVENTECH CONNECT”) with
                    respect to usage of (1) Microsoft Windows EXE version called
                    “MAVENCONNECT” which will be installed on the console of
                    laptop and/or desktop, as the case may be, (2) Web Version
                    called “BusCRS” and (3) Mobility Transit Solution
                    Application by name and style of ‘MAVEN’ App which is the
                    mobile version of the MAVEN CONNET Android platform
                    (accessible on Google ‘PlayStore’) and iOS platform
                    (accessible of Apple’s ‘iStore’) so herein customized,
                    enhanced and licensed for internal usage by MAVENTECH to
                    USER .
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.2</li>
                <li className="content">
                    In addition to the above right to use of MAVENTECH CONNECT,
                    MAVENTECH shall provide to USER maintenance, enhancement and
                    optimisation support services for MAVENTECH CONNECT.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.3 </li>
                <li className="content">
                    USER expressly agree and ensures that USER shall not have
                    the right to sell, gift, grant, lease, sub-license or
                    sub-contract MAVENTECH CONNECT and the API software product
                    in manner to any third parties. In the event of the
                    violation of the aforementioned obligation, MAVENTECH shall
                    have the right to terminate this Agreement with immediate
                    effect as well as obtain any other legal remedies from USER
                    for breach of contract and breach of confidentiality
                    obligation.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.4 </li>
                <li className="content">
                    During the tenure of this Agreement, USER agrees that its
                    bus tickets bookings would be through MAVENTECH CONNECT and
                    USER shall not use any other bus ticket booking software
                    except as per Clause No. 2.5. In the event that it is
                    brought to the notice of MAVENTECH that USER is using other
                    bus ticketing software or that the volumes (as mutually
                    agreed by the Parties) are not being met, then MAVENTECH
                    shall have the right to terminate the Agreement with
                    immediate effect.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.5 </li>
                <li className="content">
                    MAVENTECH shall with specific regard to Clause 2.1 of this
                    Agreement the software product, MAVEN shall be customized
                    and enhanced by MAVENTECH for USER to enable USER`s team to
                    view and monitor a live Bus chart on their mobile devices
                    and input and perform other important instructions with
                    respect to managing USER’s user/s’ bus ticket booking as
                    well as cancellation, fare change, passenger boarding, etc.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.6 </li>
                <li className="content">
                    Further MAVENTECH shall with specific regard to Clause 2.1
                    of this Agreement with regard to the developed and Usage
                    Licensed ‘MAVEN’ app to USER shall be so developed to be
                    utilised/implemented for utility in electronic ticketing
                    machine/POS Machines or their equivalent whereby USER’s
                    user/s as well USER’s personnel are enabled to book bus
                    tickets at designated location counters, bus stands and
                    inside buses.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.7 </li>
                <li className="content">
                    The Parties agree herein that the software so developed as
                    envisioned in Clause 2.1 of this Agreement on the whole
                    shall be structured so as to enable implementation
                    integration initially or subsequently, as the case may be of
                    wallets like PayTM, PhonePe, Google Pay, Amazon Pay and Jio
                    Money can also be integrated to accept payment from
                    customers.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.8 </li>
                <li className="content">
                    MAVENTECH shall within the scope of this Agreement shall
                    also provide USER with the following scope of services
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">2.9.1.</li>
                        <li className="content">
                            MAVENTECH shall provide USER such ‘On the Ground
                            Support Service’ to resolve day to day operational
                            issues.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">2.9.2.</li>
                        <li className="content">
                            MAVENTECH shall also execute for USER such ‘Custom
                            Software Development’ to meet new requirements of
                            USER from time to time on mutually agreed timeline
                            and cost. Further both the parties mutually agree
                            that No profit element of MAVENTECH in advertisement
                            services.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.9</li>
                <li className="content">
                    MAVENTECH shall within the scope of service to be rendered
                    under Clause 2.2 of this Agreement, shall develop for
                    provision to USER: the USER Passenger Information Software
                    (PIS) and CRS/ OPRS and same is to be utilised by USER to
                    display information on major boarding stations
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.10</li>
                <li className="content">
                    MAVENTECH shall further within the scope of services to be
                    rendered under Clause 2.2 of this Agreement, MAVENTECH shall
                    provide maintenance, optimisation and enhancement for all
                    the Usage Licensed and developed software so mentioned in
                    this Agreement as well as also:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">2.11.1.</li>
                        <li className="content">
                            Maintenance, optimise and enhance the server
                            infrastructure along with 24x7 monitoring of the
                            same in order to speedily and efficiently address
                            any issue can be quickly addressed/ fixed.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">2.11.2.</li>
                        <li className="content">
                            All the above-mentioned software and new updates
                            shall be deployed on the server infrastructure.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">2.11 </li>
                <li className="content">
                    MAVENTECH shall with specific regard to Clause 2.5 of this
                    Agreement with regard to the provision and developing
                    software to integrate MAVENTECH CONNECT with accounting
                    software Tally(licensed ERP version) for USER , the same
                    shall be so developed by MAVENTECH to inculcate a modus for
                    system data synced with the accounting software by name and
                    style of “Tally” with minimal manual input and intervention
                    by MAVENTECH personnel, subject to provision by MAVENTECH
                    pre-requisite ledgers and a description of such data to be
                    input in the said ledgers
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">3. </li>
                <li className="content">
                    GENERAL RIGHTS, DUTIES AND OBLIGATIONS
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">3.1 </li>
                <li className="content">
                    Either party may use the name, logo and etc, of the other
                    party in advertisement of itself with prior written consent
                    of the other party. Notwithstanding anything contained in
                    this Agreement, the ownership, title and all other
                    intellectual property rights and other rights in such name,
                    logo etc of each Party shall remain vested in such Party, at
                    all times. Nothing herein shall be construed as a transfer
                    of any rights, title or interest in the same to the other
                    Party.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">3.2 </li>
                <li className="content">
                    Each party shall provide such co–operation as the other
                    party reasonably requests in order to give full effect to
                    the provisions of this Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">3.3 </li>
                <li className="content">
                    USER is required to provide to MAVENTECH a copy of the
                    documents listed in Exhibit B.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">3.4 </li>
                <li className="content">
                    MAVENTECH hereby agrees and acknowledges that any data dump
                    by MAVENTECH, if required, shall only take between 11 PM and
                    7 AM. USER further agrees and acknowledges that there shall
                    only be customer driven hits on MAVENTECH servers during
                    core business hours (7 AM to 11 PM).
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 4. </li>
                <li className="content">COMMERCIAL TERMS</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.1 </li>
                <li className="content">
                    Usage License and Maintenance Fee by USER: USER shall pay to
                    MAVENTECH monthly CRS Software Usage License and Maintenance
                    fee per Bus/POS Device or fee per Bus/Device if they are
                    using MAVENTECH CONNECT and other allied software therewith
                    so provided as per the terms and conditions of this
                    Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.2 </li>
                <li className="content">
                    The Usage License and Maintenance Fee charges will be
                    collected monthly by MAVENTECH by the 25th day of every
                    calendar month. Further No hidden cost apart from agreed
                    commercials will be charged by MAVENTECH as mentioned above.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.3 </li>
                <li className="content">
                    At the time of mutual termination of this Agreement the
                    balance amount will be adjusted against any dues of USER and
                    the balance have to be paid by USER to MAVENTECH within
                    thirty (30) days from the date of termination.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.4 </li>
                <li className="content">
                    The Parties to this Agreement herewith expressly agree and
                    covenant herein that where at any time during the Term of
                    this Agreement. In case the number of USER’s buses exceeds
                    100% more of the number of buses as on date of signing of
                    this Agreement, the Commercial Terms as entailed in Clause 4
                    of this Agreement will also be subject to
                    escalation/increase with mutual consent of the Parties.
                </li>
            </ul>
            .
            <ul className="tnc-wrap">
                <li className="srno">4.5 </li>
                <li className="content">COMMISSIONS PAYABLE TO MANTIS:</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.5.1 </li>
                <li className="content">
                    MAVENTECH’ Website: For any bus tickets of USER so booked by
                    MAVENTECH, its Agents or its contractual business partners,
                    MANTIS TECH SERVICES PRIVATE LIMITED, on its web portal
                    www.travelyaari.com and www.IamGDS.com; MAVENTECH or MANTIS
                    TECH SERVICES PRIVATE LIMITED shall be entitled to collect
                    service fees as agreed through official communications on
                    the ticket amount plus GST on each booking from customers.
                    MAVENTECH shall retain the service fees and pay remaining
                    booking amount to USER on weekly basis from the date of the
                    bus journey (means basic fare will be returned to USER less
                    service fees and TDS deduction).
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.5.2 </li>
                <li className="content">
                    Prevailing ticket price will be paid back to USER by
                    MAVENTECH on weekly basis within Monday-to-Monday settlement
                    period of time
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">4.6 </li>
                <li className="content">
                    Taxes: Applicable goods and services tax / other taxes if
                    applicable on service provided by USER will be charged from
                    the travellers and shall be the liability of USER and shall
                    indemnify and hold harmless, MAVENTECH, from any liability
                    in this regard.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">5. </li>
                <li className="content">REPRESENTATIONS &amp; WARRANTIES</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">5.1 </li>
                <li className="content">
                    Each Party represents and warrants that:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i) </li>
                        <li className="content">
                            it is duly incorporated legal entity under the laws
                            of India,{" "}
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii) </li>
                        <li className="content">
                            it has full power to enter into and complete the
                            transactions required hereunder,
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii) </li>
                        <li className="content">
                            that this Agreement is enforceable against it in
                            accordance with its terms,
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iv) </li>
                        <li className="content">
                            that the activities contemplated hereunder do not
                            conflict with or constitute a breach of or default
                            under any contractor or commitments to which it is a
                            party or under any applicable law, and
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(v) </li>
                        <li className="content">
                            it has duly obtained all the necessary and
                            applicable Usage Licenses, permissions and
                            authorizations required to fulfil its obligations
                            under this Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6. </li>
                <li className="content">CONFIDENTIALITY</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6.1 </li>
                <li className="content">
                    Definition: “Confidential Information” means:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i) </li>
                        <li className="content">
                            The existence of this Agreement and any information
                            regarding the terms and conditions of this
                            Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii) </li>
                        <li className="content">
                            Any information relating to business practices of
                            MAVENTECH or USER communicated under this Agreement.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii) </li>
                        <li className="content">
                            Any information, in whatever form, designed by the
                            Party disclosing the information (“Disclosing
                            Party”) in writing as confidential, proprietary or
                            marked with words of like import when provided to
                            the Party receiving the information (“Receiving
                            Party”); and
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6.2 </li>
                <li className="content">
                    Exclusions: Confidential Information shall not include
                    information which:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i) </li>
                        <li className="content">
                            At or prior to the time of disclosure by the
                            Disclosing Party was known to the Receiving Party
                            through lawful means:
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii) </li>
                        <li className="content">
                            At or after the time of disclosure by the Disclosing
                            Party becomes generally available to the public;
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp; </li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii) </li>
                        <li className="content">
                            The Receiving Party receives from a third Person who
                            is free to make such disclosure without breach of
                            any legal obligation: or
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6.3 </li>
                <li className="content">
                    Confidentiality Obligations: The Receiving Party
                    acknowledges the confidential and proprietary nature of the
                    Disclosing Party’s Confidential Information and agrees that
                    it shall keep such information at all times in the strictest
                    of confidence. The Receiving Party shall not discuss,
                    reveal, or disclose in any way the Disclosing Party’s
                    Confidential Information to any person other than to the
                    Parties to this Agreement or their employees on need-to-know
                    basis or use any Confidential Information for any purpose
                    other than as contemplated hereby, in each case, without the
                    prior written consent of the Disclosing Party. The Receiving
                    Party agrees to take all the reasonable precautions (no less
                    rigorous than the Receiving Party agrees to take with
                    respect to its own comparable Confidential Information but
                    in no less than reasonable care) to prevent unauthorized or
                    inadvertent disclosure of the Confidential Information of
                    the Disclosing Party. Upon learning of any wrongful use or
                    treatment or Confidential Information, the Receiving Party
                    shall promptly notify the Disclosing Party and shall
                    co-operate in full with Disclosing Party to protect such
                    information.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6.4 </li>
                <li className="content">
                    Legal Obligations :After prior approval from USER The
                    Receiving Party may disclose Confidential Information to the
                    extent required by any statute, regulation, order, subpoena
                    or document discovery request, from an authority of
                    competent jurisdiction provided that prior written notice or
                    email to designated people (provided in writing by the
                    Disclosing Party) of such disclosure is furnished to the
                    Disclosing Party as soon as practicable not later than 24
                    hours from receipt of notice in order to afford the
                    Disclosing Party an opportunity to seek a protective order
                    or to utilize other available procedures to protect such
                    Confidential Information (it being agreed that if the
                    Disclosing Party is unable to obtain or does not seek a
                    protective order or other protection of such Confidential
                    Information and the Receiving Party is legally compelled to
                    disclose such information, disclosure of such information to
                    the extent legally compelled shall be made without
                    liability). The Receiving Party will provide such assistance
                    and cooperation in connection therewith as the Disclosing
                    Party reasonably requests
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">6.5 </li>
                <li className="content">
                    Injunctions: In view of the difficulties of placing a
                    monetary value on such Confidential Information, the
                    Disclosing Party may be entitled to a preliminary and final
                    injunction without the necessary of posting any bond or
                    undertaking in connection therewith to prevent any further
                    breach of this confidentiality or further unauthorized use
                    of its Confidential Information or in the case of any
                    threatened breach. This remedy is separate and apart from,
                    and without prejudice to, any other remedy the Disclosing
                    Party may have under any law or equity. The Parties agree
                    that the Disclosing Party is entitled to invoke such
                    remedies of injunction without recourse to the arbitration
                    procedure under this Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">7.</li>
                <li className="content"> INDEMNIFICATION</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">7.1</li>
                <li className="content">
                    Indemnification by MAVENTECH: MAVENTECH and its Agents shall
                    indemnify, defend and hold harmless USER and the USER
                    Related Entities from and against all costs, expenses,
                    damages, proceedings, liabilities, claims etc. arising from
                    or in connection with (a) any misrepresentation or breach of
                    any representation or warranty of MAVENTECH or its Agents
                    under this Agreement (b) any breach of any covenant or
                    clause by MAVENTECH or its Agents under this Agreement; (c)
                    any representation, misrepresentation, warranty, covenant or
                    agreement MAVENTECH may make with respect to USER or any of
                    its products or services to MAVENTECH customers or any other
                    entity, without the express written consent of USER and (d)
                    all third-party claims brought against USER arising from or
                    in connection with MANTIS’ or its Agents’ performance on
                    nonperformance of its obligations under this Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">7.2</li>
                <li className="content">
                    Indemnifications by USER : USER and its Agents shall
                    indemnify, defend and hold harmless MAVENTECH and the
                    MAVENTECH’ Related Entities from and against all costs,
                    expenses, damages, proceedings, liabilities, claims etc.
                    arising from or in connection with (a) any misrepresentation
                    or breach of any representation or warranty of USER or its
                    Agents under this Agreement; (b) any breach of any covenant
                    or clause by USER or its Agents under this Agreement; (c)
                    any representation, misrepresentation, warranty, covenant or
                    agreement USER may make with respect to MAVENTECH or any of
                    its products or services, to any other entity, without the
                    express written consent of MANTIS; and (d) all thirdparty
                    claims brought against MAVENTECH arising from or in
                    connection with USER or its Agents’ performance or
                    non-performance of its obligations under this Agreement. For
                    the purpose of this Clause, the phrase “Related Entities”
                    shall mean and include MAVENTECH’s employees, partners,
                    directors, representatives, group companies, sister concerns
                    etc. and any reference to MAVENTECH in this clause shall
                    mean and include its Related Entities.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">7.3</li>
                <li className="content">
                    Limitations on Indemnifications: Notwithstanding the
                    sections above, no Indemnified Party shall be entitled to
                    indemnification pursuant to the above section to the extent
                    attributable to the fraud, negligence or wilful misconduct
                    by such Indemnified Party or its Related Entities.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">7.4</li>
                <li className="content"> Indemnification Procedure:</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(i)</li>
                        <li className="content">
                            A Party seeking indemnification (the “Indemnified
                            Party”) shall promptly notify the other Party (the
                            “Indemnifying Party”) in writing of any claim for
                            indemnification, provided, that failure to give such
                            notice shall not relieve the Indemnifying Party or
                            any liability hereunder (except to the extent to the
                            Indemnifying Party has suffered actual material
                            prejudice by such failure.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(ii)</li>
                        <li className="content">
                            The Indemnified Party shall tender sole defence and
                            contact of such claim to the Indemnifying Party. The
                            Indemnified Party shall, if requested by the
                            Indemnifying Party, give reasonable assistance to
                            the Indemnifying Party in defence of any claim. The
                            Indemnifying Party shall reimburse the Indemnified
                            Party for any reasonable legal expenses directly
                            incurred from providing such assistance, as such
                            expenses are incurred. The Indemnified Party shall
                            not make any admission or compromise with respect to
                            an indemnified claim whatsoever without the prior
                            written consent of the Indemnifying Party.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">(iii)</li>
                        <li className="content">
                            The Indemnifying Party shall have the right to
                            consent to the entry of judgment with respect to or
                            otherwise settle, an indemnified claim with the
                            prior written consent of the Indemnified Party,
                            which consent shall not be unreasonably withheld;
                            provided, however, that the Indemnified Party may
                            withhold its consent if any such judgment or
                            settlement imposes and unreimbursed monetary or
                            continuing non-monetary obligation on such Party or
                            does not include an unconditional release of that
                            Party and its Affiliates from all liability in
                            respect of claims that are the subject matter of the
                            indemnified claim.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">8.</li>
                <li className="content">
                    LIMITATION OF WARRANTIES, REMEDIES &amp; LIABILITY
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">8.1</li>
                        <li className="content">
                            Warranty Disclaimer: Except for the warranties
                            expressly mentioned in this Agreement, each party
                            acknowledges and agrees that all resources and
                            services provided under this Agreement by the other
                            party are provided “as it,” with all faults and
                            without any warranty, condition, guarantee or
                            representation of any kind whatsoever, express or
                            implied, in law or in fact, oral or in writing,
                            including without limitation any warranty,
                            condition, accuracy, non-interruption, completeness,
                            merchantability, fitness for a particular purpose,
                            or non-infringement or the like.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">8.2</li>
                        <li className="content">
                            Liability Disclaimer: Except in connection with an
                            indemnification claim pursuant to the
                            indemnification section above, in no event shall
                            either party or any related entity of such party be
                            liable to the other party, a related entity of such
                            party or any third person for any indirect special,
                            incidental, consequential, exemplary or punitive
                            damages of any kind (including without limitation
                            lost profits, lost savings, loss of data, loss of
                            business opportunities) arising out of or in
                            connection with this Agreement, the services or the
                            Usage Licensed products, whether based in contract,
                            tort (including without limitation negligence) or
                            otherwise even if the party providing the resource
                            or service or a related entity of such party has
                            been advised of the possibility of such damages or
                            should have foreseen such damages.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">&nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno">8.3</li>
                        <li className="content">
                            Limitation of Liability: Subject to the
                            above-mentioned clause and notwithstanding anything
                            contained in this Agreement, the total liability of
                            either party under this Agreement shall not, in any
                            circumstances, exceed the total value received in
                            irrespective of the nature of the claim which the
                            claim arises irrespective of the nature of the claim
                            which results in such liability and whether based on
                            contract or tort or any other theory of law.
                            However, the parties agree that such limitation of
                            liability will not be applicable in the event of
                            breach of confidentiality provisions, fraud and
                            wilful misconduct of either party.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">9</li>
                <li className="content">TERM &amp;TERMINATION</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 9.1</li>
                <li className="content">
                    Term: This Agreement commence on the Effective Date
                    mentioned in Exhibit A and continues for the Effective
                    Duration mentioned in Exhibit A, or until it is superseded
                    by another signed contractual agreement or annulled through
                    mutual consent. This Agreement shall automatically be
                    renewed unless either Party decide not to renew the
                    Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 9.2</li>
                <li className="content">
                    Mutual Termination Rights: In addition to any other right or
                    remedy it may have, either USER or MAVENTECH may terminate
                    this Agreement without any default or judicial intervention
                    being required, only in the event that:
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno"> (i)</li>
                        <li className="content">
                            Immediately terminate when proceeding in bankruptcy,
                            Corporate Insolvency Resolution proceedings are
                            instituted by or against the other Party, or the
                            other Party terminates its business activities for
                            any other reason:
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno"> (ii)</li>
                        <li className="content">
                            There is any breach of, of failure to comply with,
                            any of the terms or conditions of this Agreement by
                            the other Party which breach of failure is not
                            remedied within thirty (30) days after notice of
                            such breach or failure: or
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno"> (iii)</li>
                        <li className="content">
                            USER gives three (3) months prior notice in writing
                            without cause to the MAVENTECH. However, during the
                            said notice period, both Parties shall continue to
                            exert best efforts to perform their respective
                            obligations under this Agreement. If website is not
                            in working condition, then three (3) months’ notice
                            period is not applicable and USER can terminate
                            Agreement immediately.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    <ul className="tnc-wrap">
                        <li className="srno"> (iv)</li>
                        <li className="content">
                            MAVENTECH has right to terminate this Agreement for
                            default of payment by USER, if USER fails to pay the
                            CRS Software Usage License and Maintenance feeas per
                            the terms hereto for more than 3 months. In the case
                            termination for default of payment MAVENTECH Shall
                            give a notice of 30 days before termination
                        </li>
                    </ul>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 9.3</li>
                <li className="content">
                    Consequences of Termination: On termination or expiry of
                    this Agreement, all accrued liabilities and any subsisting
                    rights and duties created by provision of this Agreement
                    depending upon their nature or context shall survive the
                    expiry, variation or termination and shall remain in full
                    force and effect notwithstanding expiry, variation or
                    termination. As a consequence of termination. MAVENTECH
                    shall be entitled to deconstruct, revert and take back such
                    software infrastructure so provided to USER under the terms
                    and conditions of this Agreement. Further on termination of
                    the Agreement, the accrued liabilities, and any subsisting
                    rights and liabilities created by this Agreement shall come
                    to an end, whenever, if the USER requires the data of the
                    transaction made either of the accounts or of the details of
                    the passengers travelled, or the inventory brought by the
                    USER by its own efforts, shall be provided in the soft copy.
                    The Parties should delete all other entries or documents
                    have with them, to stop using the name or logo of the other
                    Party, if using with the written permission when the
                    Agreement is subsisting. On termination or expiry of this
                    Agreement USER shall not have any right to user MAVENTECH
                    OPRS software and MAVENTECH CRS software Usage License
                    provided to USER shall be cancelled.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 9.4</li>
                <li className="content">
                    No Prejudice to Other Rights: Exercise of the right of
                    termination afforded to either Party shall not prejudice any
                    other legal rights or remedies either Party may have against
                    the other in respect of any breach of the terms of this
                    Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.</li>
                <li className="content">MISCELLANEOUS</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.1</li>
                <li className="content">
                    Relationship of the Parties: This Agreement is on
                    principal-to-principal basis. The relationship of the
                    Parties under this Agreement is and at all times shall
                    remain hat of independent entries/contractors and
                    accordingly this deed of Agreement shall not create any
                    employer, employee relationship between the Parties
                    including its affiliates, employees, representatives,
                    agents, retailers, business partners, distributors etc.,
                    nothing in this Agreement of the attached Annexure /Exhibits
                    shall be constructed to create nor shall it be deemed to
                    create any partnership. Joint venture, franchise employment
                    or agency relationship between MAVENTECH and USER or their
                    representative or employees, consultants, agents, servants
                    etc. and accordingly, neither Party shall represent itself
                    as having, nor does either party have, the right, power or
                    authority to bind or otherwise create any obligation or
                    duty, express or implied, on behalf of the other of the
                    other party in any manner whatsoever.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.2</li>
                <li className="content">
                    Intellectual Property: Unless otherwise expressly provided
                    herein or otherwise agreed by the Parties, each party shall
                    retain all rights, title and interest, throughout the world,
                    in their respective intellectual Property licensed /provided
                    to the Parties or used on behalf of the Parties.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.3</li>
                <li className="content">
                    Non – Solicitation: Neither Party shall, during the term of
                    this Agreement and for one (1) year thereafter, and whether
                    on behalf of itself or on behalf of any third party, solicit
                    any employees of the other party involved with the
                    negotiations of this Agreement or that Party’s performance
                    hereunder, without the express written consent of the Party
                    that employs such employees, such consent to be at the
                    employing party’s sole and absolute discretion. If any such
                    employees should cease to be an employee of a Party, the
                    other party may solicit such employee beginning one (1) year
                    after cessation of such employment. Notwithstanding anything
                    to the contrary herein. Neither party shall be deemed to
                    have breached or violated this section (a) solely as a
                    result of generic employment advertising by that party
                    (including any “open position” or similar listing in that
                    party’s Website or other general advertising). Or (b) if any
                    employee of the other party approaches and obtains
                    employment with the other party after the date hereof solely
                    as a result of any advertising or recruitment effort
                    contemplated in clause (a) above.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.4</li>
                <li className="content">
                    Advance Intimation of Changes: MAVENTECH agrees to inform
                    USER a day in advance in case any material changes are
                    affected which might adversely affect the access mechanism
                    to its inventory.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.5</li>
                <li className="content">
                    Access &amp; Training: MAVENTECH agrees to provide USER
                    access to its technical staff, documentation and code as
                    might be required to use MAVENTECH Connect.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.6</li>
                <li className="content">
                    Force Majeure: Neither Party will be liable for any failure
                    to perform any obligation (other than payment or
                    reimbursement obligations) hereunder or form any delay in
                    the performance thereof, due to causes beyond its control,
                    including without limitation industrial disputes of whatever
                    nature, acts of God, public enemy, acts of government
                    failure of telecommunications or other calamity, provided
                    that the failure to perform due to the occurrence of force
                    majeure event will be informed in writing to the other Party
                    and shall continue its performance immediately when such
                    force majeure events are removed.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.7</li>
                <li className="content">
                    Assignment: No Party shall assign this Agreement without the
                    prior written consent of the other Party under any
                    conditions, except in connection with a corporate
                    reorganization, merger or the sale of substantially all of
                    its business or assets or substantially similar transaction.
                    Any purported assignment or transfer in violation of this
                    provision shall be void without effect.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.8</li>
                <li className="content">
                    Amendment: This Agreement may be amended, renewed as
                    mutually agreed upon by the Parties in writing, to change
                    scope and terms of this Agreement. Such changes shall be
                    incorporated as a written amendment to this Agreement duly
                    signed and executed by the authorized representatives of
                    both Parties. The Parties agree to negotiate the changes
                    requested by any of the Parties expeditiously and in good
                    faith.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> 10.9</li>
                <li className="content">
                    Notices: All notices and other correspondence under this
                    Agreement shall be in writing and shall be sufficiently
                    given if delivered personally. If sent by facsimile
                    transmission with proof of receipt by the recipient, or sent
                    overnight courier with proof of receipt, to the addresses
                    stated herein below or to such other address as either party
                    may specify by such notice.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li>Communications should be sent to:</li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    For MAVENTECH LABS PRIVATE LIMITED to:
                    <br />
                    <br />
                    <p>
                        Name: Amit Kumar Singh
                        <br />
                        Position: Director
                        <br />
                        Email: director@maventechlabs.com
                        <br />
                        Address: 1st Floor, Gopala Krishna
                        <br />
                        Complex, #45/3 Residency Road,
                        <br />
                        Bengaluru – 560025, Karnataka, India
                    </p>
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno"> &nbsp;</li>
                <li className="content">
                    Any change above contact details should be notified in
                    writing (including email).
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">10.10</li>
                <li className="content">
                    Modification and Waiver: No modifications, amendment,
                    supplement to or waiver of this Agreement or any attachment
                    hereto shall be binding upon the Parties hereto unless made
                    in writing duly signed by both parties. No invoice or other
                    similar form may vary the terms hereof, and any term thereof
                    that is inconsistent or additional to the terms hereof shall
                    not be binding. A failure or delay by either party to
                    enforce at any time any of the provisions hereof, or to
                    exercise any option which is herein provided, or to require
                    at any time performance of any of the provisions hereto
                    shall in no way be constructed to be a waiver of such
                    provisions of the Agreement.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">10.11</li>
                <li className="content">
                    Severability: The provisions of this Agreement are
                    severable, and in the event that any provisions of this
                    Agreement are determined to be invalid or enforceable under
                    any controlling law, such invalidity or unenforceability
                    shall not in any way effect the validity or enforceability
                    of the remaining provisions thereof. If any provision of
                    this Agreement is found to be invalid or unenforceable, the
                    invalid provisions shall be modified to the minimum extent
                    required to comply with applicable law, and the modified
                    provision shall be constructed as having been in effect
                    since the Effective Date.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">10.12</li>
                <li className="content">
                    Entire Agreement: This Agreement, including the schedules
                    and exhibits referred to herein and attached or to be
                    attached hereto, constitutes the entire agreement between
                    the parties and supersedes all prior agreements, promises,
                    proposals, representations, understandings and negotiations,
                    whether or not reduced no writing, between the parties
                    respecting the subject matter hereof, No change to this
                    Agreement shall be valid unless made in writing and signed
                    by the duly authorized representatives of both parties.
                </li>
            </ul>
            <ul className="tnc-wrap">
                <li className="srno">10.13</li>
                <li className="content">
                    Applicable Law &amp; Jurisdiction: The validity of this
                    Agreement, the construction and enforcement of its terms,
                    and the interpretation of the rights and duties of the
                    Parties shall be governed by the laws of India, and all
                    disputes shall be governed by the provisions of the
                    Arbitration and Conciliation Act, 1996. The venue for such
                    arbitration will be at Bengaluru, Karnataka, India before a
                    Sole Arbitrator mutually appointed by both parties subject
                    to the above, the parties submit to the exclusive
                    jurisdiction of the competent courts in Bengaluru, India.
                </li>
            </ul>
            <p className="txtCt" style={{ paddingTop: "15px" }}>
                EXHIBIT A
            </p>
            <p className="txtCt" style={{ padding: "25px 0 20px 0" }}>
                PARTICULAR INFORMATION
            </p>
            <div className="exhibitA">
                <div className="col1">
                    <b>1.</b>
                </div>
                <div className="col2">Effective Date:</div>
                <div className="col3">
                    1<sup>st</sup> day of September, 2021
                </div>
            </div>
            <p className="txtCt" style={{ paddingTop: "50px" }}>
                EXHIBIT B
            </p>
            <p className="txtCt" style={{ padding: "25px 0 20px 0" }}>
                LIST OF DOCUMENTS TO BE SUBMITTED BY USER
            </p>
            <div className="exhibitB">
                <div className="col1">1.</div>
                <div className="col2">
                    Certified Copy of MOA and AOA of USER, including list of
                    Directors, Promoters etc.
                </div>
            </div>
            <div className="exhibitB">
                <div className="col1">2.</div>
                <div className="col2">
                    Certified Copy of Resolution authorizing USER to enter into
                    this Agreement and the person authorized with executing this
                    Agreement.
                </div>
            </div>
            <div className="exhibitB">
                <div className="col1">3.</div>
                <div className="col2">Copy of Trade License of USER</div>
            </div>
            <div className="exhibitB">
                <div className="col1">4.</div>
                <div className="col2">Copy of PAN of USER</div>
            </div>
            <div className="exhibitB">
                <div className="col1">5.</div>
                <div className="col2">Copy of GSTIN certificate of USER</div>
            </div>
            <div className="exhibitB">
                <div className="col1">6.</div>
                <div className="col2">
                    Communication Address &amp; Contact Points of USER
                </div>
            </div>
            <div className="exhibitB">
                <div className="col1">7.</div>
                <div className="col2">
                    Bus Operator Registration Form or IamGDS Registration Form
                    as the case maybe
                </div>
            </div>
            <p className="grievance">
                <b>Grievance Contact Points</b>
                <br />
                For any prospective or current customers of Maventech Labs
                Private Limited, for any grievances our contact address and
                Grievance officer details are as follows:
            </p>
            <ul className="grievance-list">
                <li>
                    <b>Grievance Officer:</b> Sunita Iyer (Customer Service
                    Head)
                </li>
                <li>
                    <b>Mailing address:</b> Gopala Krishna Complex, 1st Floor,
                    #45/3 Residency Road, Bengaluru, Bangalore-560025,
                    Karnataka, India
                </li>
                <li>
                    <b>Customer Care Number:</b> 08047096674
                </li>
                <li>
                    <b>Customer Care Email Address:</b> feedback@travelyaari.com
                </li>
            </ul>
        </div>
        <div className="footer-wrap">
            <div className="footer-line">&nbsp;</div>
            <div className="footer-info">
                <div className="footer-info-left">
                    CIN Number: U72900KA2020PTC136105
                </div>
                <div className="footer-info-center">&nbsp;</div>
                <div className="footer-info-right">GSTIN: 29AANCM6095M1ZH</div>
            </div>
        </div>
    </div>
);
