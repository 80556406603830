import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { string } from "yup";
import FormikControls from "../form-components/FormikControls";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getMaintenanceurl, getRedirectUrl } from "../../utils/helpter";
const LoginForm = (props) => {
    const { setHeading } = props;
    const [isLoading, setIsloading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || "/";

    const initialValues = {
        loginFor: "B",
        code: "",
        username: "",
        password: "",
    };
    useEffect(() => {
        if (window.location.pathname.toLocaleLowerCase() === "/cargo") {
            initialValues.loginFor = "C";
            setHeading("Cargo BusCRS");
        }
    }, []);

    const validationSchema = Yup.object({
        loginFor: Yup.string().required("required"),
        code: Yup.string().required("Required"),
        username: string().required("Required"),
        password: string().required("Required"),
    });
    const handlePlatformChange = (e, setFieldValue) => {
        setFieldValue("loginFor", e.currentTarget.value);
    };
    const onSubmit = async (values) => {
        window.location.replace(getRedirectUrl(values));
        return false;
    };
    return (
        <div className="login--content">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, setFieldValue, submitForm }) => {
                    return (
                        <Form>
                            {isLoading && (
                                <div className="form-error">
                                    Authenticating...{" "}
                                </div>
                            )}
                            {loginError !== "" && !isLoading && (
                                <div className="form-error">{loginError}</div>
                            )}
                            <div
                                className="input--container"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginTop: "-2vh",
                                    marginBottom: "2vh",
                                }}
                            >
                                <RadioGroup
                                    id="userLoginPlatforms"
                                    row
                                    name="rdgLoginPlatforms"
                                    aria-labelledby="user-login-platforms"
                                    value={values.loginFor}
                                    onChange={(e) =>
                                        handlePlatformChange(e, setFieldValue)
                                    }
                                >
                                    <FormControlLabel
                                        value="B"
                                        control={
                                            <Radio
                                                onChange={() =>
                                                    {
                                                        setHeading("Bus CRS")
                                                        navigate('/bus')
                                                    }
                                                }
                                            />
                                        }
                                        label="Bus"
                                    />
                                    <FormControlLabel
                                        value="C"
                                        control={
                                            <Radio
                                                onChange={() =>
                                                    {
                                                        setHeading("Cargo BusCRS")
                                                        navigate('/cargo')
                                                    }
                                                }
                                            />
                                        }
                                        label="Cargo"
                                    />
                                    <FormControlLabel
                                        value="M"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.replace(
                                                getMaintenanceurl()
                                            );
                                        }}
                                        control={
                                            <Radio
                                                onChange={() =>
                                                    setHeading(
                                                        "Maintenance System"
                                                    )
                                                }
                                            />
                                        }
                                        label="Maintenance"
                                    />
                                </RadioGroup>
                            </div>
                            <div className="input--wrapper">
                                <FormikControls
                                    control="input"
                                    type="text"
                                    name="code"
                                    label="Enter Code"
                                    autoFocus={true}
                                    autoComplete="off"
                                    style={{ textTransform: "uppercase" }}
                                />
                            </div>
                            <div className="input--wrapper">
                                <FormikControls
                                    control="input"
                                    type="text"
                                    name="username"
                                    label="Enter Login ID"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="input--wrapper">
                                <FormikControls
                                    control="input"
                                    type="password"
                                    name="password"
                                    label="Enter Password"
                                    autoComplete="off"
                                    onKeyUp={(e) => {
                                        if (e.keyCode === 13) submitForm();
                                    }}
                                />
                            </div>
                            <div
                                className="submit--btn"
                                onClick={(e) => {
                                    submitForm();
                                }}
                            >
                                LOGIN
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default LoginForm;
