import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";
import CheckboxComponent from "./CheckboxComponent";

const FormikControls = (props) => {
    const { control, ...rest } = props
    switch(control) {
        case 'input':
            return <InputComponent {...rest} />
        case 'textarea':
        case 'select':
            return <SelectComponent {...rest} />
        case 'radio':
        case 'checkbox':
            return <CheckboxComponent {...rest} />
        case 'date':
        default:
            return null;
    }
}

export default FormikControls
