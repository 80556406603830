import { useState, useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import  TextError  from './TextError';
import {Eye , Eyeselect} from '../../styles/icons/svg';

const InputComponent = (props) => {
    const {label,name, id=name, ...rest } = props;
    const [hidePassword,setHidePassword] = useState(true);
    const [isActive,setIsActive] = useState(false);
    const inputRef = useRef(null);

    const handleFocus = e => {
        setIsActive(true);
    };

    const handleBlur = e => {
        setIsActive(false)
    }
    const showIcon = () =>{
       return  hidePassword ?  <Eyeselect /> : <Eye />;
    }

    const togglePassword = () =>{  
        inputRef.current.type =  inputRef.current.type === 'password' ? 'text' :'password';
        setHidePassword(!hidePassword);
    }
    return (
        <div className='input--content'>
            <Field innerRef={inputRef} id={id} name={name} {...rest} className="input--content" onFocus={handleFocus} onBlur={handleBlur} />
            <div className ={isActive ? "input--label active" : inputRef.current && inputRef.current.value.length > 0 && !isActive  ? " input--label filled" : "input--label"}>{props.label}</div>
            {props.type === 'password' && <div className = "icon" onClick = {togglePassword}>{showIcon()}</div>}
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default InputComponent
