import CryptoJS from 'crypto-js'
export const decryptText = (encryptedText) => {
  if(!encryptedText) return false
  var securityKey = 'c@w3br3p0rtc@rg0'
  var securityIv = 'Y0ByZzBwQHM='
  var key = CryptoJS.enc.Utf8.parse(securityKey)
  var iv = CryptoJS.enc.Base64.parse(securityIv)
  var bytes = CryptoJS.TripleDES.decrypt(encryptedText, key, { mode: CryptoJS.mode.CBC, iv: iv })
  try{
    var decryptedText = bytes.toString(CryptoJS.enc.Utf8)
    return decryptedText
  }
  catch(err){
    return "ErrorInDecrypting"
  }
  
}
export const  encrypt= (text)=> {
	var securityKey = 'c@w3br3p0rtc@rg0'
  var securityIv = 'Y0ByZzBwQHM='
  var key = CryptoJS.enc.Utf8.parse(securityKey)
  var iv = CryptoJS.enc.Base64.parse(securityIv)
	var encoded = CryptoJS.enc.Utf8.parse(text);
	var ciphertext = CryptoJS.TripleDES.encrypt(encoded, key, { mode: CryptoJS.mode.CBC, iv: iv });
	return ciphertext.toString();
}