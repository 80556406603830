import React, { useState } from "react";
import LoginForm from "../../components/login/LoginForm.Component";
import Helpline from "../../components/login/Helpline.Component";
//import ForgotPasswordForm from "../../components/login/ForgotPasswordForm.Component";

export const Entry = () => {
	const [formType, setFormType] = useState("login");
	const [heading, setHeading] = useState("BusCRS");
   
	return (
        <div className="container login-page">
            <div className ="left--part">
                <div className ="login--wrapper">
                    <div className='login--logo--wrapper' key='login-header-logo'>
                        <img key='company-logo' src ="/maventech-logo.png" style={{width:'66px'}} alt ="Maventech Labs Pvt Ltd." /></div>
                    {/* <div className ="head--txt">Maintenance System</div> */}
                    <div className ="head--txt">{heading}</div>
                    {/* <div className ="nav--tab">
                        <div 
                        className ={formType==='login' ? 'active' :''}
                        onClick = {()=>setFormType('login')}
                        >Login</div>
                        <div 
                        className ={formType==='pass' ? 'active' :''}
                        onClick = {()=>setFormType('pass')}
                        >Forgot Password</div>
                    </div> */}
                    {/* { formType === 'login' ? <LoginForm setHeading={setHeading}/> : <ForgotPasswordForm goBackToLogin={setFormType} /> } */}
                    { formType === 'login' ? <LoginForm setHeading={setHeading}/> : <></> }

                </div>
                <div style={{marginBottom:"10px"}}>
                    <label>
                        <input name="" type="checkbox" value="" disabled={true} checked="checked" />You agree to our <a href="/terms_of_use" target="_blank" rel="noreferrer">Terms of Use</a> agreement and <a target="_blank" rel="noreferrer" href="/privacy_policy">Privacy Policy</a>
                    </label>
                </div>
                <div className="copy--right--txt"> &copy; {new Date().getFullYear()} Operated by Maventech Labs Private Limited.</div>
            </div>
            <div className = "right--part custom-right-part"  >
                <Helpline />
            </div>
        </div>
	);
};