import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

const CheckboxComponent = ({ active, isDisabled, handleChange }) => {
  const [checked, setChecked] = React.useState(active);
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
    handleChange(event,event.target.checked);
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleCheckboxChange}
      inputProps={{ "aria-label": "controlled" }}
      disabled={isDisabled}
    />
  );
};

export default CheckboxComponent;
