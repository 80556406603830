import { useState, useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import  TextError  from './TextError';

const SelectComponent = (props) => {
    const {label,name, id=name, className="input--content", options, ...rest } = props;
    const [isActive,setIsActive] = useState(false);
    const inputRef = useRef(null);

    const handleFocus = e => {
        setIsActive(true);
    };

    const handleBlur = e => {
        setIsActive(false)
    }
    
    return (
        <div className='input--content'>
            <Field as='select' innerRef={inputRef} id={id} name={name} {...rest} className={className}  onFocus={handleFocus} onBlur={handleBlur}>
                {
                    options.map(option => {
                        return (
                            <option key={option.value} value={option.value}>{option.key}</option>
                        )
                    })
                }
            </Field>
            <div className ={isActive ? "input--label active" : inputRef.current && inputRef.current.value.length > 0 && !isActive  ? " input--label filled" : "input--label"}>{props.label}</div>
            <ErrorMessage name={name} component={TextError} />
        </div>
    )
}

export default SelectComponent
