export const Eye = () => (
    <svg fill="#000" width="20px" height="20px" viewBox="0 0 512 512"><path d="M256,421C147.78,421,49.91,358.72.58,258.51a5.7,5.7,0,0,1,0-5C49.91,153.28,147.78,91,256,91s206.09,62.26,255.42,162.47a5.7,5.7,0,0,1,0,5C462.09,358.72,364.22,421,256,421ZM12,256c47.78,94.82,141,153.6,244,153.6S452.17,350.82,500,256c-47.78-94.82-141-153.6-244-153.6S59.83,161.18,12,256Z" /><path d="M256,352.71A96.71,96.71,0,1,1,352.71,256,96.82,96.82,0,0,1,256,352.71Zm0-182A85.33,85.33,0,1,0,341.33,256,85.43,85.43,0,0,0,256,170.67Z" /></svg>
)
export const Eyeselect = () => (
    <svg fill="#000" width="20px" x="0px" y="0px" viewBox="0 0 512 512">
        <g>
            <path d="M71.3,341c-1.4,0-2.8-0.5-3.8-1.5C39.7,314.2,17,284,0.5,250.3c-0.8-1.6-0.8-3.4,0-5
                C49.9,145.1,147.7,82.8,256,82.8c19.3,0,38.5,2,57.4,6c3.1,0.7,5.1,3.7,4.4,6.8s-3.7,5.1-6.8,4.4c-18.1-3.8-36.5-5.8-55-5.8
                C153,94.2,59.8,153,12,247.8c15.8,31.4,37.2,59.6,63.1,83.3c2.3,2.1,2.5,5.7,0.4,8C74.4,340.3,72.8,341,71.3,341L71.3,341z"/>
            <path d="M256,412.8c-40.9,0.1-81.3-8.9-118.4-26.4c-2.8-1.3-4.1-4.7-2.7-7.6c1.3-2.8,4.7-4.1,7.6-2.7l0,0
                c35.5,16.7,74.3,25.3,113.5,25.3c103,0,196.2-58.8,244-153.6c-24.7-48.9-62-89.1-108-116.4c-2.7-1.6-3.6-5.1-2-7.8
                c1.6-2.7,5.1-3.6,7.8-2c48.8,28.9,88.1,71.6,113.7,123.7c0.8,1.6,0.8,3.4,0,5C462,350.5,364.2,412.8,256,412.8z"/>
            <path d="M256,344.5c-21.1,0.1-41.7-6.8-58.4-19.7c-2.6-1.8-3.3-5.3-1.5-7.9c1.8-2.6,5.3-3.3,7.9-1.5
                c0.2,0.1,0.4,0.3,0.5,0.4c14.8,11.3,32.9,17.4,51.5,17.3c47.1-0.1,85.3-38.2,85.3-85.3c0.1-18.6-6-36.7-17.3-51.5
                c-2.1-2.4-1.8-6,0.5-8c2.4-2.1,6-1.8,8,0.5c0.2,0.2,0.3,0.4,0.4,0.5c12.9,16.7,19.8,37.3,19.7,58.4
                C352.6,301.2,309.3,344.4,256,344.5z"/>
            <path d="M165.2,247.1h-0.4c-3.1-0.2-5.5-2.9-5.3-6.1c3.4-48.1,41.7-86.3,89.7-89.7c3.1-0.2,5.9,2.2,6.1,5.3
                c0.2,3.1-2.2,5.9-5.3,6.1c-42.4,3-76.1,36.7-79.1,79.1C170.6,244.8,168.2,247.1,165.2,247.1z"/>
            <path d="M23.6,497.8c-3.1,0-5.7-2.6-5.7-5.7c0-1.5,0.6-2.9,1.7-4L492.2,15.5c2.4-2,6-1.6,8,0.9c1.7,2.1,1.7,5.1,0,7.1
                L27.7,496.1C26.6,497.2,25.2,497.8,23.6,497.8z"/>
        </g>
    </svg>
)

export const Dropdown = () => (
    <svg fill="#000" x="0px" y="0px" width="10px" height="10px" viewBox="0 0 10 10" ><g><path d="M0,2.5h10l-5,5L0,2.5z" /></g></svg>
)

export const Notification = () => (
    <svg fill="#fff" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 16 19" ><path d="M8,19c1.1,0,2-0.9,2-1.9H6C6,18.1,6.9,19,8,19z M14,13.2V8.3c0-3-1.6-5.5-4.5-6.2V1.5C9.5,0.7,8.8,0,8,0S6.5,0.7,6.5,1.5 v0.7C3.6,2.8,2,5.3,2,8.3v4.9l-2,1.9v1h16v-1L14,13.2z" /></svg>
)


export const getIcons = el => {
    switch (el.toLowerCase()) {
        case 'dashboard':
            return (
                <svg fill="#fff" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" ><path d="M0,10h8V0H0V10z M0,18h8v-6H0V18z M10,18h8V8h-8V18z M10,0v6h8V0H10z" /></svg>
            );
        case 'master':
            return (
                <svg fill="#fff" x="0px" y="0px" width="19px" height="15px" viewBox="0 0 19 15" ><path d="M0,3.8h3.5V0H0V3.8z M5.2,15h3.5v-3.8H5.2V15z M0,15h3.5v-3.8H0V15z M0,9.4h3.5V5.6H0V9.4z M5.2,9.4h3.5V5.6H5.2V9.4z
                M10.4,0v3.8h3.5V0H10.4z M5.2,3.8h3.5V0H5.2V3.8z M10.4,9.4h3.5V5.6h-3.5V9.4z M10.4,15h3.5v-3.8h-3.5V15z M15.5,0v3.8H19V0H15.5z
                M15.5,9.4H19V5.6h-3.5V9.4z M15.5,15H19v-3.8h-3.5V15z"/>
                </svg>
            );
        case 'transactions':
            return (
                <svg fill="#fff" x="0px" y="0px" width="18px" height="21px" viewBox="0 0 18 21" >
                    <g>
                        <path d="M2.2,3.3v4.4c0,0.4,0.2,0.8,0.4,1c0.3,0.2,0.6,0.2,0.9,0c0.3-0.2,0.4-0.6,0.4-1V5.5h12.3c0.3,0,0.6-0.2,0.8-0.6
                    c0.2-0.3,0.2-0.8,0-1.1c-0.2-0.3-0.4-0.6-0.8-0.6L2.2,3.3z"/>
                        <path d="M14.5,0c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8l2.6,3.3v0c0.2,0.2,0.4,0.3,0.6,0.3
                    c0.2,0,0.5-0.1,0.6-0.3C17.9,5,18,4.7,18,4.4c0-0.3-0.1-0.6-0.3-0.8l-2.6-3.3l0,0C14.9,0.1,14.7,0,14.5,0L14.5,0z"/>
                        <path d="M17.1,3.3c-0.2,0-0.4,0.1-0.6,0.3l-2.6,3.3v0c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.6,0.3
                    c0.2,0,0.5-0.1,0.6-0.3l2.6-3.3l0,0C17.9,5,18,4.7,18,4.4c0-0.3-0.1-0.6-0.3-0.8C17.5,3.4,17.3,3.3,17.1,3.3L17.1,3.3z"/>
                        <path d="M14.9,12.2c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v2.2H1.8c-0.3,0-0.6,0.2-0.8,0.6c-0.2,0.3-0.2,0.8,0,1.1
                    c0.2,0.3,0.4,0.6,0.8,0.6h14.1v-4.4h0c0-0.3-0.1-0.6-0.3-0.8C15.4,12.3,15.2,12.2,14.9,12.2L14.9,12.2z"/>
                        <path d="M0.8,15.5c-0.2,0-0.4,0.2-0.6,0.4C0.1,16.1,0,16.3,0,16.6c0,0.3,0.1,0.5,0.3,0.7l2.6,3.3C3.1,21,3.5,21.1,3.8,21
                    c0.3-0.1,0.5-0.4,0.6-0.8c0.1-0.4,0-0.8-0.2-1.1l-2.6-3.3C1.3,15.6,1.1,15.4,0.8,15.5L0.8,15.5z"/>
                        <path d="M3.5,12.2c-0.2,0-0.4,0.1-0.6,0.3l-2.6,3.3C0.1,16,0,16.3,0,16.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.6,0.3
                    c0.2,0,0.5-0.1,0.6-0.3l2.6-3.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8C4,12.3,3.8,12.2,3.5,12.2L3.5,12.2z"/>
                    </g>
                </svg>
            );
        case 'reports':
            return (
                <svg fill="#fff" x="0px" y="0px" width="17px" height="17px" viewBox="0 0 17 17" >
                    <g>
                        <g>
                            <path d="M11.3,0H1.9C0.9,0,0,0.9,0,1.9l0,13.2c0,1,0.8,1.9,1.9,1.9h13.2c1,0,1.9-0.9,1.9-1.9V5.7L11.3,0z M4.7,13.2
                            c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C5.7,12.8,5.2,13.2,4.7,13.2z M4.7,9.4C4.2,9.4,3.8,9,3.8,8.5
                            s0.4-0.9,0.9-0.9S5.7,8,5.7,8.5S5.2,9.4,4.7,9.4z M4.7,5.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9
                            S5.2,5.7,4.7,5.7z M10.4,6.6V1.4l5.2,5.2H10.4z"/>
                        </g>
                    </g>
                </svg>
            );
        case 'communication':
            return (
                <svg fill="#fff" x="0px" y="0px" width="24px" height="21px" viewBox="0 0 24 24">
                    <path d="M21 6h-2v9H6v2c0 0.55 0.45 1 1 1h11l4 4V7c0-0.55-0.45-1-1-1Zm-4 6V3c0-0.55-0.45-1-1-1H3c-0.55 0-1 0.45-1 1v14l4-4h10c0.55 0 1-0.45 1-1Z" />
                </svg>

            );
        case 'configs':
            return (
                <svg fill="#fff" x="0px" y="0px" width="19px" height="19px" viewBox="0 0 19 19" >
                    <path d="M18.8,15.5l-7.8-7.9c0.8-2,0.3-4.3-1.3-6C7.9-0.1,5.3-0.4,3.2,0.5l3.7,3.7L4.3,6.9L0.6,3.1c-1,2.1-0.6,4.7,1.1,6.4
                    c1.6,1.6,4,2.1,6,1.3l7.8,7.9c0.3,0.3,0.9,0.3,1.2,0l2-2C19.1,16.4,19.1,15.8,18.8,15.5z"/>
                </svg>
            );
        case 'admin':
            return (
                <svg fill="#fff" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16">
                    <path d="M8,8c2.2,0,4-1.8,4-4s-1.8-4-4-4S4,1.8,4,4S5.8,8,8,8z M8,10c-2.7,0-8,1.3-8,4v2h16v-2C16,11.3,10.7,10,8,10z" />
                </svg>
            );
        default:
            return null;

    }
}

export const Setting = () => (
    <svg fill="#fff" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 18 18" >
        <path d="M15.9,9.9c0-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9l2-1.5c0.2-0.1,0.2-0.4,0.1-0.6L16.1,3c-0.1-0.2-0.4-0.3-0.6-0.2
        l-2.3,0.9c-0.5-0.4-1-0.7-1.6-0.9l-0.3-2.4c0-0.2-0.2-0.4-0.5-0.4H7.1C6.9,0,6.7,0.2,6.7,0.4L6.3,2.8C5.8,3,5.3,3.3,4.8,3.6L2.5,2.7
        C2.3,2.7,2,2.7,1.9,3L0.1,6.1C0,6.3,0,6.5,0.2,6.6l2,1.5c0,0.3-0.1,0.6-0.1,0.9s0,0.6,0.1,0.9l-2,1.5c-0.2,0.1-0.2,0.4-0.1,0.6
        L1.9,15c0.1,0.2,0.4,0.3,0.6,0.2l2.3-0.9c0.5,0.4,1,0.7,1.6,0.9l0.3,2.4c0,0.2,0.2,0.4,0.5,0.4h3.7c0.2,0,0.4-0.2,0.5-0.4l0.3-2.4
        c0.6-0.2,1.1-0.5,1.6-0.9l2.3,0.9c0.2,0.1,0.5,0,0.6-0.2l1.9-3.1c0.1-0.2,0.1-0.4-0.1-0.6L15.9,9.9z M9,12.4c-1.9,0-3.5-1.5-3.5-3.4
        S7.1,5.6,9,5.6s3.5,1.5,3.5,3.4S10.9,12.4,9,12.4z"/>
    </svg>
)

export const Account = () => (
    <svg fill="#fff" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 19 19">
        <path d="M9.5,0C4.3,0,0,4.3,0,9.5S4.3,19,9.5,19S19,14.7,19,9.5S14.7,0,9.5,0z M9.5,2.8c1.6,0,2.9,1.3,2.9,2.8s-1.3,2.9-2.9,2.9
        S6.7,7.3,6.7,5.7S7.9,2.8,9.5,2.8z M9.5,16.3c-2.4,0-4.5-1.2-5.7-3.1c0-1.9,3.8-2.9,5.7-2.9c1.9,0,5.7,1,5.7,2.9
        C14,15.1,11.9,16.3,9.5,16.3z"/>
    </svg>
)

export const Filter = () => (
    <svg
        fill="#777"
        x="0px"
        y="0px"
        width="16px"
        height="11px"
        viewBox="0 0 16 11"
    >
        <path d="M6.2,11h3.6V9.2H6.2V11z M0,0v1.8h16V0H0z M2.7,6.4h10.7V4.6H2.7V6.4z" />
    </svg>
);

export const Sort = () => (
    <svg
        fill="#777"
        x="0px"
        y="0px"
        width="16.5px"
        height="11.5px"
        viewBox="0 0 16.5 11.5"
    >
        <g>
            <rect y="4.3" width="10" height="1.7" />
            <rect y="8.6" width="5" height="1.7" />
            <rect width="15" height="1.7" />
            <polygon points="14.6,8.8 14.6,3.5 13.4,3.5 13.4,8.8 11.5,8.8 14,11.5 16.5,8.8 	" />
        </g>
    </svg>
);

export const RefreshSvg = ({ size = '30px', color = '#1b43a6' }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size} fill={color}>
        <path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
    </svg>
)

export const Download = () => (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15.5H12V13.7353H0M12 5.79412H8.57143V0.5H3.42857V5.79412H0L6 11.9706L12 5.79412Z" fill="#1E59A7" />
    </svg>
)

export const DownloadExcelIcon = () => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
        <path d="M10 0H2C0.89 0 0 0.89 0 2V18C0 19.11 0.89 20 2 20H14C15.11 20 16 19.11 16 18V6L10 0ZM8 17L4 13H6.5V10H9.5V13H12L8 17ZM9 7V1.5L14.5 7H9Z" fill="#1E59A7" />
    </svg>
)

export const ShareIcon = () => (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 10.81C10.93 10.81 10.42 11.035 10.03 11.3875L4.6825 8.275C4.72 8.1025 4.75 7.93 4.75 7.75C4.75 7.57 4.72 7.3975 4.6825 7.225L9.97 4.1425C10.375 4.5175 10.9075 4.75 11.5 4.75C12.0967 4.75 12.669 4.51295 13.091 4.09099C13.5129 3.66903 13.75 3.09674 13.75 2.5C13.75 1.90326 13.5129 1.33097 13.091 0.90901C12.669 0.487053 12.0967 0.25 11.5 0.25C10.9033 0.25 10.331 0.487053 9.90901 0.90901C9.48705 1.33097 9.25 1.90326 9.25 2.5C9.25 2.68 9.28 2.8525 9.3175 3.025L4.03 6.1075C3.625 5.7325 3.0925 5.5 2.5 5.5C1.90326 5.5 1.33097 5.73705 0.90901 6.15901C0.487053 6.58097 0.25 7.15326 0.25 7.75C0.25 8.34674 0.487053 8.91903 0.90901 9.34099C1.33097 9.76295 1.90326 10 2.5 10C3.0925 10 3.625 9.7675 4.03 9.3925L9.37 12.505C9.3325 12.6625 9.31 12.8275 9.31 13C9.31 14.2075 10.2925 15.1825 11.5 15.1825C12.7075 15.1825 13.69 14.2075 13.69 13C13.69 12.4192 13.4593 11.8621 13.0486 11.4514C12.6379 11.0407 12.0808 10.81 11.5 10.81Z" fill="#004F9E" />
    </svg>
)

export const CopyIcon = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 1.63636H6V13.0909H17M17 14.7273H6C5.46957 14.7273 4.96086 14.5549 4.58579 14.248C4.21071 13.9411 4 13.5249 4 13.0909V1.63636C4 1.20237 4.21071 0.786158 4.58579 0.47928C4.96086 0.172403 5.46957 0 6 0H17C17.5304 0 18.0391 0.172403 18.4142 0.47928C18.7893 0.786158 19 1.20237 19 1.63636V13.0909C19 13.5249 18.7893 13.9411 18.4142 14.248C18.0391 14.5549 17.5304 14.7273 17 14.7273ZM14 18H2C1.46957 18 0.960859 17.8276 0.585786 17.5207C0.210714 17.2138 0 16.7976 0 16.3636V4.90909H2V16.3636H14V18Z" fill="#666666" />
    </svg>
)

export const Export = () => (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 0.5H1.5C1.10218 0.5 0.720644 0.658035 0.43934 0.93934C0.158035 1.22064 0 1.60218 0 2V14C0 14.3978 0.158035 14.7794 0.43934 15.0607C0.720644 15.342 1.10218 15.5 1.5 15.5H10.5C10.8978 15.5 11.2794 15.342 11.5607 15.0607C11.842 14.7794 12 14.3978 12 14V5L7.5 0.5ZM8.85 14H7.5L6 11.45L4.5 14H3.15L5.325 10.625L3.15 7.25H4.5L6 9.8L7.5 7.25H8.85L6.675 10.625L8.85 14ZM6.75 5.75V1.625L10.875 5.75H6.75Z" fill="#1E59A7" />
    </svg>
)

export const Share = () => (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0938 0.0881256C9.73438 0.247493 9.5 0.606852 9.5 1.00058V3.00049H6C2.9625 3.00049 0.5 5.46288 0.5 8.50025C0.5 12.0407 3.04688 13.6219 3.63125 13.9406C3.70937 13.9844 3.79688 14 3.88438 14C4.225 14 4.5 13.7219 4.5 13.3844C4.5 13.15 4.36562 12.9344 4.19375 12.7751C3.9 12.4969 3.5 11.9501 3.5 11.0001C3.5 9.34396 4.84375 8.00027 6.5 8.00027H9.5V10.0002C9.5 10.3939 9.73125 10.7533 10.0938 10.9126C10.4563 11.072 10.875 11.0064 11.1687 10.7439L16.1687 6.2441C16.3781 6.05348 16.5 5.78474 16.5 5.50038C16.5 5.21602 16.3812 4.94728 16.1687 4.75667L11.1687 0.256868C10.875 -0.00874501 10.4531 -0.0743671 10.0938 0.0881256Z" fill="#1E59A7" />
    </svg>
)

export const CloseIcon = () => (
    <svg x="0px" y="0px" width="16" height="16" viewBox="0 0 14.959 14.959" enableBackground="new 0 0 14.959 14.959" space="preserve" fill="#ffffff">
        <path d="M8.505,7.479l6.454,6.454l-1.025,1.026L7.478,8.505l-6.452,6.454L0,13.933l6.453-6.454L0,1.026L1.026,0l6.452,6.453
        L13.934,0l1.025,1.026L8.505,7.479z"></path>
    </svg>
)

export const BusIcon = () => (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 4.5C3.58 4.5 0 5 0 8.5V18.5C0 19.35 0.37 20.16 1 20.72V22.5C1 23.05 1.45 23.5 2 23.5H3C3.55 23.5 4 23.05 4 22.5V21.5H12V22.5C12 23.05 12.45 23.5 13 23.5H14C14.55 23.5 15 23.05 15 22.5V20.72C15.63 20.16 16 19.35 16 18.5V8.5C16 5 12.42 4.5 8 4.5ZM3.5 19.5C2.67 19.5 2 18.83 2 18C2 17.17 2.67 16.5 3.5 16.5C4.33 16.5 5 17.17 5 18C5 18.83 4.33 19.5 3.5 19.5ZM12.5 19.5C11.67 19.5 11 18.83 11 18C11 17.17 11.67 16.5 12.5 16.5C13.33 16.5 14 17.17 14 18C14 18.83 13.33 19.5 12.5 19.5ZM14 13.5H2V8.5H14V13.5ZM20 4.5V14.5C20 15.35 19.63 16.16 19 16.72V18.5C19 19.05 18.55 19.5 18 19.5H17.88C17.95 19.18 18 18.85 18 18.5V8.5C18 2.5 11 2.5 8 2.5C7.09 2.5 5.8 2.5 4.46 2.67C5.55 0.82 8.5 0.5 12 0.5C16.42 0.5 20 1 20 4.5Z" fill="#1E59A7" />
    </svg>
)

export const CalendarIcon = () => (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 18H2V7H16M13 0V2H5V0H3V2H2C0.89 2 0 2.89 0 4V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V4C18 2.89 17.1 2 16 2H15V0" fill="#1E59A7" />
    </svg>
)

export const PlusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.23077 1.23077C9.23077 0.55 8.68077 0 8 0C7.31923 0 6.76923 0.55 6.76923 1.23077V6.76923H1.23077C0.55 6.76923 0 7.31923 0 8C0 8.68077 0.55 9.23077 1.23077 9.23077H6.76923V14.7692C6.76923 15.45 7.31923 16 8 16C8.68077 16 9.23077 15.45 9.23077 14.7692V9.23077H14.7692C15.45 9.23077 16 8.68077 16 8C16 7.31923 15.45 6.76923 14.7692 6.76923H9.23077V1.23077Z" fill="#1E59A7" />
    </svg>
)

export const MinusIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 1.5C16 2.32969 15.45 3 14.7692 3H1.23077C0.55 3 0 2.32969 0 1.5C0 0.670312 0.55 0 1.23077 0H14.7692C15.45 0 16 0.670312 16 1.5Z" fill="#1E59A7" />
    </svg>
)

export const RightArrowIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.160034 6.99996V8.99996H12.16L6.66003 14.5L8.08003 15.92L16 7.99996L8.08003 0.0799561L6.66003 1.49996L12.16 6.99996H0.160034Z" fill="#1E59A7" />
    </svg>
)

export const LeftArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_0_1083)">
            <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_0_1083">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
)