import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {Terms} from './components/tnc/terms'
import {PrivacyPolicy} from './components/privacy/privacy'
import {Entry} from './components/entry/Entry.Page'
function App() {
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path="/terms_of_use"
                    element={<Terms />}
                />
                <Route
                    exact
                    path="/privacy_policy"
                    element={< PrivacyPolicy />}
                />
                <Route path="*" element={<Entry />} />
            </Routes>
        </Router>
    );
}

export default App;
