const Helpline = () => {
    return (
        <div className ="helpline--wrapper">
            <div className ="head--txt--wrap">
                <div>Helpline Numbers</div>
                <div>Talk to our product supports for the business account</div>
            </div>
            <div className ="helpline--wrap" style={{paddingTop:'10px'}}>
                <div>North India</div>
                <div>011-411-83866 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Rajasthan</div>
                <div>079-480-61655 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Gujarat</div>
                <div>079-480-61655 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Maharashtra and Goa</div>
                <div>020-715-31448 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Vidharbha and Chhattisgarh</div>
                <div>020-715-31448 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Madhya Pradesh</div>
                <div>073-148-54988 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>South India</div>
                <div>080-470-96674 (10:30 AM to 08:30 PM)</div>
            </div>
            <div className ="helpline--wrap">
                <div>Rest of India</div>
                <div>080-470-96674 (10:30 AM to 08:30 PM)</div>
            </div>
        </div>
    )
}

export default Helpline
